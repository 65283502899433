import WidgetService from '@/v1/services/widget/widgetService.v1';
import { captureException } from '@/v1/packages/common/plugins/sentry';
import TokenService from '@/v1/packages/authentication/services/token';
import { getDeviceId } from '@/utils/loadNSure';

import clientWidget from '@/v1/network/clients/widget';
import clientProcessing from '@/v1/network/clients/processing';

import store from '@/v1/store';

const params = {};
let oneTimeToken = null;
let oneTimeTokenPromise = null;

const oneTimeTokenLogin = async () => {
  if (oneTimeTokenPromise) {
    return oneTimeTokenPromise;
  }

  const nSureDeviceId = await getDeviceId();

  oneTimeTokenPromise = WidgetService.passwordlessLogin({
    ...params,
    oneTimeToken,
    nSurePartnerId: store.getters['request/nSure'].nSurePartnerId,
    nSureDeviceId,
  })
    .then(response => TokenService.setTokens(response.data))
    .catch(error => captureException({ error }));

  return oneTimeTokenPromise;
};

export const requestInterceptor = async config => {
  if (config.useAuth && oneTimeToken) {
    await oneTimeTokenLogin();

    oneTimeToken = null;

    await Promise.all([
      store.dispatch('getFeatureFlags', null, { root: true }),
      store.dispatch('loadClientData', null, { root: true }),
    ]);
  }

  return config;
};

export const initOneTimeTokenLogin = ({ requestId, oneTimeToken: initialOneTimeToken, locale }) => {
  params.requestId = requestId;
  params.locale = locale;

  oneTimeToken = initialOneTimeToken;

  // Logout any user
  TokenService.removeTokens();

  clientWidget.interceptors.request.use(
    requestInterceptor,
    error => Promise.reject(error),
  );
  clientProcessing.interceptors.request.use(
    requestInterceptor,
    error => Promise.reject(error),
  );
};
